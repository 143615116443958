import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/thisisengineering-raeng-64YrPKiguAE-unsplash.jpg'
import pic02 from '../images/img_1292_2.jpg'
import pic03 from '../images/alex-wong-l5Tzv1alcps-unsplash.jpg'

class Main extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Just because we all use the same tech doesn't mean we 
            learn them the same way.
          </p>
          <p>
            At Coo Software, we believe expertise in coding and innovation requires
            knowing when to apply the right architectural patterns, tools, and
            coding methodologies. </p>
          <p>
            With our customized lessons based on your code, tech stack,
            and existing knowledge and delivered by a supportive instrutor,
            you'll learn code in a safe environment and grow faster than you
            thought possible.
          </p>
          <p>
            We will work with you to design a customized plan focusing on the
            areas you need to grow into next, and our expert instructional
            team will deliver live lessons to your developers.
          </p> 
          <p>
            Whether you're working on a web application, data analytics,
            or improving your system architecture or CI/CD pipelines, Coo Software
            can provide the professional support and training you need to build
            skills in-house.
          </p>
          <p>
            Register for a free consultation today.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Sondra</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Nice to meet you! I'm Sondra, the owner and lead engineer of Coo Software. I'm a software architect by trade, with more than 10 years of professional software development experience across a breadth of tools and stacks.
          </p>
          <p>
            I've been coaching developers for 4 years, from providing in-house training to new juinor devs, to teaching 0-to-junior dev bootcamps, and guiding senior-level collegues through a maze of new stacks and tools as they foray into new areas of software development.
          </p>
          <p>
            I want to help you grow to the next level, and I will be here to support you by teaching you the tools you need to keep your application moving towards the future.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Expertise</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Our curriculum team brings expertise across a wide range of tools, including cloud architecture, web development, data visualization, data analytics, network protocols, machine learning, big data, and more. Just like you, our team learns and grows every day.
          </p>
          <p>
            Sign up for a free consultation today to learn how we can help your team.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Book a consultation</h2>
          <div 
            className="calendly-inline-widget"
            data-url="https://calendly.com/sondra-miller/training-consultation"
            style={{ minWidth: '320px', height: '580px' }} />
            <br/>
          <ul className="icons">
            {/* <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li> */}
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
